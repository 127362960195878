import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import Pagination from "../common/Pagination";
import {
  getVendors,
  ApproveVendorApi,
  deleteVendorApi,
  VendorBlockApi,
  getAllApproveVendors,
} from "../../services/User/UserService";
import defaultImage from "../../images/emptyImg.jpg";
import Switch from "react-switch";
import toast from "react-hot-toast";
import PageTitle from "../layouts/PageTitle";
import RejectIcon from "../../icons/decline.png";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import pdfIcon from "../../icons/pdf.png";
import RejectVendorMessageModal from "../modal/RejectVendorMessageModal";
import ShowImage from "../modal/ShowImage";

export default function PendingVendorList(props) {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [vendorRejectData, setVendorRejectData] = useState({
    id: "",
    status: "",
  });
  const [search, setSearch] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(false);

  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }
  const limit = 10;
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.content}
    </Tooltip>
  );

  function handleError(e) {
    e.target.src = defaultImage;
  }
  function getTableData() {
    setLoader(true);
    getVendors(currentPage, limit, search)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.vendorCount;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );
  function showRejectMessageModal(id, status) {
    setVendorRejectData({
      id: id,
      status: status,
    });
    setShowRejectModal(true);
  }

  async function deleteVender(id) {
    setLoader(true);
    try {
      const response = await deleteVendorApi(id);
      toast.success("Vendor deleted successfully");
      getTableData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function handleApproveVender(id, status) {
    setLoader(true);
    const postData = {
      vendorId: id,
      status: status,
    };
    try {
      const response = await ApproveVendorApi(postData);
      toast.success("Vendor Approve Successfully");
      getTableData();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <div>
        <div className="p-3">
          <PageTitle activeMenu="Vendors List" motherMenu="Vendor Management" />
        </div>
        <div className="form-group pl-3" style={{ width: "35%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    {/* <th>
                      <strong>image</strong>
                    </th> */}
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>PHONE</strong>
                    </th>
                    <th className="text-center">
                      <strong>Proof of ownership</strong>
                    </th>

                    <th className="text-center">
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list?.vendors?.map((item, i) => (
                    <tr key={i}>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        {item?.name}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        {item?.email}
                      </td>

                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        <span>+{item?.countryCode} </span>
                        {item?.contact}
                      </td>
                      <td className="text-center hover-table">
                        {item?.document?.mimetype?.endsWith("/pdf") ? (
                          <a
                            href={`https://staging-api.bookwineries.com/${item?.document?.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className=" pointer p-2"
                              src={pdfIcon}
                              height={50}
                              width={50}
                            />
                          </a>
                        ) : (
                          <img
                            src={`https://staging-api.bookwineries.com/${item?.document?.path}`}
                            onClick={() => prevImage(item?.document?.path)}
                            height={40}
                            width={40}
                            onError={handleError}
                            className="pointer"
                          />
                        )}
                      </td>
                      <td className="text-center">
                        <div className="d-flex">
                          <button
                            className="btn btn-xs btn-danger"
                            onClick={() =>
                              showRejectMessageModal(item?._id, "reject")
                            }
                          >
                            Reject
                          </button>

                          <button
                            className="btn btn-xs btn-success ml-2"
                            onClick={() =>
                              handleApproveVender(item._id, "accept")
                            }
                          >
                            Approve
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {list?.vendorCount === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between">
                <div className="dataTables_info">
                  Total Vendor Requests
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {list?.vendorCount}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {showRejectModal && (
        <RejectVendorMessageModal
          show={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          data={vendorRejectData}
          refresh={getTableData}
          successMessage={"Vendor Rejected Successfully"}
          api={ApproveVendorApi}
        />
      )}
      {showImage && (
        <ShowImage
          show={showImage}
          onHide={() => setShowImage(false)}
          image={selectedImage}
        />
      )}
    </div>
  );
}
